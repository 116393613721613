import validate from "/builds/rialtic/workflows/console-ui/node_modules/.pnpm/nuxt@3.11.2_@parcel+watcher@2.4.1_@types+node@20.12.7_encoding@0.1.13_idb-keyval@6.2.1_ioredi_g2d7tbfeghjxc43ksbzjch4y2m/node_modules/nuxt/dist/pages/runtime/validate.js";
import _01_45auth_45required_45global from "/builds/rialtic/workflows/console-ui/packages/nuxt-auth0/src/runtime/middleware/auth-required.global.ts";
import manifest_45route_45rule from "/builds/rialtic/workflows/console-ui/node_modules/.pnpm/nuxt@3.11.2_@parcel+watcher@2.4.1_@types+node@20.12.7_encoding@0.1.13_idb-keyval@6.2.1_ioredi_g2d7tbfeghjxc43ksbzjch4y2m/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  _01_45auth_45required_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  "is-content-admin": () => import("/builds/rialtic/workflows/console-ui/apps/content/middleware/is-content-admin.ts")
}