<script setup lang="ts">
import { useActiveUser } from '~/stores/activeUser'

const activeUser = useActiveUser()
const { $auth0, $pendo, $datadog } = useNuxtApp()
const { user } = $auth0()
const config = useRuntimeConfig().public

const { data: userData } = await useAsyncData(
  'activeUser',
  () => activeUser.fetchUser(),
  {
    immediate: true,
    watch: [user],
  },
)

const userPermissions = computed(() => userData.value?.permissions || [])

provide('user-permissions', userPermissions)

watch(
  user,
  (userData) => {
    if (userData?.email && userData.sub) {
      const { email, sub: id, name: full_name } = userData

      const roles: string[] = userData['https://auth.rialtic.io/roles']

      $datadog.setUser({
        id,
        name: full_name,
        email,
      })
      
      $pendo.identify({
        visitor: {
          id,
          email,
          full_name,
          role: roles[0],
        },
        account: {
          id: email?.split('@')[1],
          rialtic_app: 'policy_manager',
          is_internal_app: true,
        },
      })
    }
  },
  {
    immediate: true,
  },
)
</script>

<template>
  <div class="font-poppins bg-surface-bg">
    <NuxtLoadingIndicator
      color="repeating-linear-gradient(to right,#6F7CCD 0%,#28B7F0 62%,#35CDDA 100%)"
    />
    <NuxtLayout>
      <NuxtPage />
    </NuxtLayout>
    <NuxtSnackbar />
    <AuthLoading :message="config.auth.loginMessage" />
  </div>
</template>
