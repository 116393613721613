import { default as edit_45type0xnPVzWI6ZMeta } from "/builds/rialtic/workflows/console-ui/apps/content/pages/edit-type.vue?macro=true";
import { default as _91id_93eyf0uxlUvuMeta } from "/builds/rialtic/workflows/console-ui/apps/content/pages/engine/[id].vue?macro=true";
import { default as createevcjGrcDIBMeta } from "/builds/rialtic/workflows/console-ui/apps/content/pages/engine/create.vue?macro=true";
import { default as indexOvpIy5WSi9Meta } from "/builds/rialtic/workflows/console-ui/apps/content/pages/engine/index.vue?macro=true";
import { default as enginePU9C24CxsyMeta } from "/builds/rialtic/workflows/console-ui/apps/content/pages/engine.vue?macro=true";
import { default as indexkkO8gXZfdMMeta } from "/builds/rialtic/workflows/console-ui/apps/content/pages/index.vue?macro=true";
import { default as enginesdAkerqk4z3Meta } from "/builds/rialtic/workflows/console-ui/apps/content/pages/policy-editor/[id]/engines.vue?macro=true";
import { default as indexQN6qhi3AErMeta } from "/builds/rialtic/workflows/console-ui/apps/content/pages/policy-editor/[id]/index.vue?macro=true";
import { default as index9vrbOqEYPGMeta } from "/builds/rialtic/workflows/console-ui/apps/content/pages/policy-editor/[id]/reference-document/index.vue?macro=true";
import { default as _91id_93qu5GMlvVpvMeta } from "/builds/rialtic/workflows/console-ui/apps/content/pages/policy-editor/[id].vue?macro=true";
import { default as indexepW35Vh59gMeta } from "/builds/rialtic/workflows/console-ui/apps/content/pages/policy-editor/index.vue?macro=true";
import { default as policy_45editorLLGk0WQMjIMeta } from "/builds/rialtic/workflows/console-ui/apps/content/pages/policy-editor.vue?macro=true";
import { default as indexYpdp5QNfCsMeta } from "/builds/rialtic/workflows/console-ui/apps/content/pages/policy/[id]/index.vue?macro=true";
import { default as editdyO1Ms4aXFMeta } from "/builds/rialtic/workflows/console-ui/apps/content/pages/policy/[id]/reference-document/[docId]/edit.vue?macro=true";
import { default as addCcv5mV8jtdMeta } from "/builds/rialtic/workflows/console-ui/apps/content/pages/policy/[id]/reference-document/add.vue?macro=true";
import { default as createzashcOLrDYMeta } from "/builds/rialtic/workflows/console-ui/apps/content/pages/policy/create.vue?macro=true";
import { default as indexDSvGmpbOVXMeta } from "/builds/rialtic/workflows/console-ui/apps/content/pages/policy/index.vue?macro=true";
import { default as _91id_93gRuspLi3ixMeta } from "/builds/rialtic/workflows/console-ui/apps/content/pages/provider/[id].vue?macro=true";
import { default as createLp9ChsjLRDMeta } from "/builds/rialtic/workflows/console-ui/apps/content/pages/provider/create.vue?macro=true";
import { default as indexUcMVjtCI1QMeta } from "/builds/rialtic/workflows/console-ui/apps/content/pages/provider/index.vue?macro=true";
import { default as providerEDUsJsBCprMeta } from "/builds/rialtic/workflows/console-ui/apps/content/pages/provider.vue?macro=true";
import { default as region45rGD4VT8CMeta } from "/builds/rialtic/workflows/console-ui/apps/content/pages/region.vue?macro=true";
import { default as source_45documenthJXzGbgJwSMeta } from "/builds/rialtic/workflows/console-ui/apps/content/pages/source-document.vue?macro=true";
import { default as source_45type_45level_451IlAzL3VcGYMeta } from "/builds/rialtic/workflows/console-ui/apps/content/pages/source-type-level-1.vue?macro=true";
import { default as source_45type_45level_452yrUxJAhIxZMeta } from "/builds/rialtic/workflows/console-ui/apps/content/pages/source-type-level-2.vue?macro=true";
import { default as _91id_93EwyqkDMG4cMeta } from "/builds/rialtic/workflows/console-ui/apps/content/pages/topic/[id].vue?macro=true";
import { default as createpQRTQ7yZt1Meta } from "/builds/rialtic/workflows/console-ui/apps/content/pages/topic/create.vue?macro=true";
import { default as index0DIPsRUAMsMeta } from "/builds/rialtic/workflows/console-ui/apps/content/pages/topic/index.vue?macro=true";
import { default as topicLr14skJlSeMeta } from "/builds/rialtic/workflows/console-ui/apps/content/pages/topic.vue?macro=true";
import { default as auth_45callbackvzPvcsneV7Meta } from "/builds/rialtic/workflows/console-ui/packages/nuxt-auth0/src/runtime/pages/auth-callback.vue?macro=true";
import { default as auth_45login2NyvXp0YlaMeta } from "/builds/rialtic/workflows/console-ui/packages/nuxt-auth0/src/runtime/pages/auth-login.vue?macro=true";
import { default as auth_45statustPEZIB9azrMeta } from "/builds/rialtic/workflows/console-ui/packages/nuxt-auth0/src/runtime/pages/auth-status.vue?macro=true";
export default [
  {
    name: edit_45type0xnPVzWI6ZMeta?.name ?? "edit-type",
    path: edit_45type0xnPVzWI6ZMeta?.path ?? "/edit-type",
    meta: edit_45type0xnPVzWI6ZMeta || {},
    alias: edit_45type0xnPVzWI6ZMeta?.alias || [],
    redirect: edit_45type0xnPVzWI6ZMeta?.redirect,
    component: () => import("/builds/rialtic/workflows/console-ui/apps/content/pages/edit-type.vue").then(m => m.default || m)
  },
  {
    name: enginePU9C24CxsyMeta?.name ?? undefined,
    path: enginePU9C24CxsyMeta?.path ?? "/engine",
    meta: enginePU9C24CxsyMeta || {},
    alias: enginePU9C24CxsyMeta?.alias || [],
    redirect: enginePU9C24CxsyMeta?.redirect,
    component: () => import("/builds/rialtic/workflows/console-ui/apps/content/pages/engine.vue").then(m => m.default || m),
    children: [
  {
    name: _91id_93eyf0uxlUvuMeta?.name ?? "engine-id",
    path: _91id_93eyf0uxlUvuMeta?.path ?? ":id()",
    meta: _91id_93eyf0uxlUvuMeta || {},
    alias: _91id_93eyf0uxlUvuMeta?.alias || [],
    redirect: _91id_93eyf0uxlUvuMeta?.redirect,
    component: () => import("/builds/rialtic/workflows/console-ui/apps/content/pages/engine/[id].vue").then(m => m.default || m)
  },
  {
    name: createevcjGrcDIBMeta?.name ?? "engine-create",
    path: createevcjGrcDIBMeta?.path ?? "create",
    meta: createevcjGrcDIBMeta || {},
    alias: createevcjGrcDIBMeta?.alias || [],
    redirect: createevcjGrcDIBMeta?.redirect,
    component: () => import("/builds/rialtic/workflows/console-ui/apps/content/pages/engine/create.vue").then(m => m.default || m)
  },
  {
    name: indexOvpIy5WSi9Meta?.name ?? "engine",
    path: indexOvpIy5WSi9Meta?.path ?? "",
    meta: indexOvpIy5WSi9Meta || {},
    alias: indexOvpIy5WSi9Meta?.alias || [],
    redirect: indexOvpIy5WSi9Meta?.redirect,
    component: () => import("/builds/rialtic/workflows/console-ui/apps/content/pages/engine/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: indexkkO8gXZfdMMeta?.name ?? "index",
    path: indexkkO8gXZfdMMeta?.path ?? "/",
    meta: indexkkO8gXZfdMMeta || {},
    alias: indexkkO8gXZfdMMeta?.alias || [],
    redirect: indexkkO8gXZfdMMeta?.redirect,
    component: () => import("/builds/rialtic/workflows/console-ui/apps/content/pages/index.vue").then(m => m.default || m)
  },
  {
    name: policy_45editorLLGk0WQMjIMeta?.name ?? undefined,
    path: policy_45editorLLGk0WQMjIMeta?.path ?? "/policy-editor",
    meta: policy_45editorLLGk0WQMjIMeta || {},
    alias: policy_45editorLLGk0WQMjIMeta?.alias || [],
    redirect: policy_45editorLLGk0WQMjIMeta?.redirect,
    component: () => import("/builds/rialtic/workflows/console-ui/apps/content/pages/policy-editor.vue").then(m => m.default || m),
    children: [
  {
    name: _91id_93qu5GMlvVpvMeta?.name ?? undefined,
    path: _91id_93qu5GMlvVpvMeta?.path ?? ":id()",
    meta: _91id_93qu5GMlvVpvMeta || {},
    alias: _91id_93qu5GMlvVpvMeta?.alias || [],
    redirect: _91id_93qu5GMlvVpvMeta?.redirect,
    component: () => import("/builds/rialtic/workflows/console-ui/apps/content/pages/policy-editor/[id].vue").then(m => m.default || m),
    children: [
  {
    name: enginesdAkerqk4z3Meta?.name ?? "policy-editor-id-engines",
    path: enginesdAkerqk4z3Meta?.path ?? "engines",
    meta: enginesdAkerqk4z3Meta || {},
    alias: enginesdAkerqk4z3Meta?.alias || [],
    redirect: enginesdAkerqk4z3Meta?.redirect,
    component: () => import("/builds/rialtic/workflows/console-ui/apps/content/pages/policy-editor/[id]/engines.vue").then(m => m.default || m)
  },
  {
    name: indexQN6qhi3AErMeta?.name ?? "policy-editor-id",
    path: indexQN6qhi3AErMeta?.path ?? "",
    meta: indexQN6qhi3AErMeta || {},
    alias: indexQN6qhi3AErMeta?.alias || [],
    redirect: indexQN6qhi3AErMeta?.redirect,
    component: () => import("/builds/rialtic/workflows/console-ui/apps/content/pages/policy-editor/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: index9vrbOqEYPGMeta?.name ?? "policy-editor-id-reference-document",
    path: index9vrbOqEYPGMeta?.path ?? "reference-document",
    meta: index9vrbOqEYPGMeta || {},
    alias: index9vrbOqEYPGMeta?.alias || [],
    redirect: index9vrbOqEYPGMeta?.redirect,
    component: () => import("/builds/rialtic/workflows/console-ui/apps/content/pages/policy-editor/[id]/reference-document/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: indexepW35Vh59gMeta?.name ?? "policy-editor",
    path: indexepW35Vh59gMeta?.path ?? "",
    meta: indexepW35Vh59gMeta || {},
    alias: indexepW35Vh59gMeta?.alias || [],
    redirect: indexepW35Vh59gMeta?.redirect,
    component: () => import("/builds/rialtic/workflows/console-ui/apps/content/pages/policy-editor/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: indexYpdp5QNfCsMeta?.name ?? "policy-id",
    path: indexYpdp5QNfCsMeta?.path ?? "/policy/:id()",
    meta: indexYpdp5QNfCsMeta || {},
    alias: indexYpdp5QNfCsMeta?.alias || [],
    redirect: indexYpdp5QNfCsMeta?.redirect,
    component: () => import("/builds/rialtic/workflows/console-ui/apps/content/pages/policy/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: editdyO1Ms4aXFMeta?.name ?? "policy-id-reference-document-docId-edit",
    path: editdyO1Ms4aXFMeta?.path ?? "/policy/:id()/reference-document/:docId()/edit",
    meta: editdyO1Ms4aXFMeta || {},
    alias: editdyO1Ms4aXFMeta?.alias || [],
    redirect: editdyO1Ms4aXFMeta?.redirect,
    component: () => import("/builds/rialtic/workflows/console-ui/apps/content/pages/policy/[id]/reference-document/[docId]/edit.vue").then(m => m.default || m)
  },
  {
    name: addCcv5mV8jtdMeta?.name ?? "policy-id-reference-document-add",
    path: addCcv5mV8jtdMeta?.path ?? "/policy/:id()/reference-document/add",
    meta: addCcv5mV8jtdMeta || {},
    alias: addCcv5mV8jtdMeta?.alias || [],
    redirect: addCcv5mV8jtdMeta?.redirect,
    component: () => import("/builds/rialtic/workflows/console-ui/apps/content/pages/policy/[id]/reference-document/add.vue").then(m => m.default || m)
  },
  {
    name: createzashcOLrDYMeta?.name ?? "policy-create",
    path: createzashcOLrDYMeta?.path ?? "/policy/create",
    meta: createzashcOLrDYMeta || {},
    alias: createzashcOLrDYMeta?.alias || [],
    redirect: createzashcOLrDYMeta?.redirect,
    component: () => import("/builds/rialtic/workflows/console-ui/apps/content/pages/policy/create.vue").then(m => m.default || m)
  },
  {
    name: indexDSvGmpbOVXMeta?.name ?? "policy",
    path: indexDSvGmpbOVXMeta?.path ?? "/policy",
    meta: indexDSvGmpbOVXMeta || {},
    alias: indexDSvGmpbOVXMeta?.alias || [],
    redirect: indexDSvGmpbOVXMeta?.redirect,
    component: () => import("/builds/rialtic/workflows/console-ui/apps/content/pages/policy/index.vue").then(m => m.default || m)
  },
  {
    name: providerEDUsJsBCprMeta?.name ?? undefined,
    path: providerEDUsJsBCprMeta?.path ?? "/provider",
    meta: providerEDUsJsBCprMeta || {},
    alias: providerEDUsJsBCprMeta?.alias || [],
    redirect: providerEDUsJsBCprMeta?.redirect,
    component: () => import("/builds/rialtic/workflows/console-ui/apps/content/pages/provider.vue").then(m => m.default || m),
    children: [
  {
    name: _91id_93gRuspLi3ixMeta?.name ?? "provider-id",
    path: _91id_93gRuspLi3ixMeta?.path ?? ":id()",
    meta: _91id_93gRuspLi3ixMeta || {},
    alias: _91id_93gRuspLi3ixMeta?.alias || [],
    redirect: _91id_93gRuspLi3ixMeta?.redirect,
    component: () => import("/builds/rialtic/workflows/console-ui/apps/content/pages/provider/[id].vue").then(m => m.default || m)
  },
  {
    name: createLp9ChsjLRDMeta?.name ?? "provider-create",
    path: createLp9ChsjLRDMeta?.path ?? "create",
    meta: createLp9ChsjLRDMeta || {},
    alias: createLp9ChsjLRDMeta?.alias || [],
    redirect: createLp9ChsjLRDMeta?.redirect,
    component: () => import("/builds/rialtic/workflows/console-ui/apps/content/pages/provider/create.vue").then(m => m.default || m)
  },
  {
    name: indexUcMVjtCI1QMeta?.name ?? "provider",
    path: indexUcMVjtCI1QMeta?.path ?? "",
    meta: indexUcMVjtCI1QMeta || {},
    alias: indexUcMVjtCI1QMeta?.alias || [],
    redirect: indexUcMVjtCI1QMeta?.redirect,
    component: () => import("/builds/rialtic/workflows/console-ui/apps/content/pages/provider/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: region45rGD4VT8CMeta?.name ?? "region",
    path: region45rGD4VT8CMeta?.path ?? "/region",
    meta: region45rGD4VT8CMeta || {},
    alias: region45rGD4VT8CMeta?.alias || [],
    redirect: region45rGD4VT8CMeta?.redirect,
    component: () => import("/builds/rialtic/workflows/console-ui/apps/content/pages/region.vue").then(m => m.default || m)
  },
  {
    name: source_45documenthJXzGbgJwSMeta?.name ?? "source-document",
    path: source_45documenthJXzGbgJwSMeta?.path ?? "/source-document",
    meta: source_45documenthJXzGbgJwSMeta || {},
    alias: source_45documenthJXzGbgJwSMeta?.alias || [],
    redirect: source_45documenthJXzGbgJwSMeta?.redirect,
    component: () => import("/builds/rialtic/workflows/console-ui/apps/content/pages/source-document.vue").then(m => m.default || m)
  },
  {
    name: source_45type_45level_451IlAzL3VcGYMeta?.name ?? "source-type-level-1",
    path: source_45type_45level_451IlAzL3VcGYMeta?.path ?? "/source-type-level-1",
    meta: source_45type_45level_451IlAzL3VcGYMeta || {},
    alias: source_45type_45level_451IlAzL3VcGYMeta?.alias || [],
    redirect: source_45type_45level_451IlAzL3VcGYMeta?.redirect,
    component: () => import("/builds/rialtic/workflows/console-ui/apps/content/pages/source-type-level-1.vue").then(m => m.default || m)
  },
  {
    name: source_45type_45level_452yrUxJAhIxZMeta?.name ?? "source-type-level-2",
    path: source_45type_45level_452yrUxJAhIxZMeta?.path ?? "/source-type-level-2",
    meta: source_45type_45level_452yrUxJAhIxZMeta || {},
    alias: source_45type_45level_452yrUxJAhIxZMeta?.alias || [],
    redirect: source_45type_45level_452yrUxJAhIxZMeta?.redirect,
    component: () => import("/builds/rialtic/workflows/console-ui/apps/content/pages/source-type-level-2.vue").then(m => m.default || m)
  },
  {
    name: topicLr14skJlSeMeta?.name ?? undefined,
    path: topicLr14skJlSeMeta?.path ?? "/topic",
    meta: topicLr14skJlSeMeta || {},
    alias: topicLr14skJlSeMeta?.alias || [],
    redirect: topicLr14skJlSeMeta?.redirect,
    component: () => import("/builds/rialtic/workflows/console-ui/apps/content/pages/topic.vue").then(m => m.default || m),
    children: [
  {
    name: _91id_93EwyqkDMG4cMeta?.name ?? "topic-id",
    path: _91id_93EwyqkDMG4cMeta?.path ?? ":id()",
    meta: _91id_93EwyqkDMG4cMeta || {},
    alias: _91id_93EwyqkDMG4cMeta?.alias || [],
    redirect: _91id_93EwyqkDMG4cMeta?.redirect,
    component: () => import("/builds/rialtic/workflows/console-ui/apps/content/pages/topic/[id].vue").then(m => m.default || m)
  },
  {
    name: createpQRTQ7yZt1Meta?.name ?? "topic-create",
    path: createpQRTQ7yZt1Meta?.path ?? "create",
    meta: createpQRTQ7yZt1Meta || {},
    alias: createpQRTQ7yZt1Meta?.alias || [],
    redirect: createpQRTQ7yZt1Meta?.redirect,
    component: () => import("/builds/rialtic/workflows/console-ui/apps/content/pages/topic/create.vue").then(m => m.default || m)
  },
  {
    name: index0DIPsRUAMsMeta?.name ?? "topic",
    path: index0DIPsRUAMsMeta?.path ?? "",
    meta: index0DIPsRUAMsMeta || {},
    alias: index0DIPsRUAMsMeta?.alias || [],
    redirect: index0DIPsRUAMsMeta?.redirect,
    component: () => import("/builds/rialtic/workflows/console-ui/apps/content/pages/topic/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: auth_45callbackvzPvcsneV7Meta?.name ?? "auth-callback",
    path: auth_45callbackvzPvcsneV7Meta?.path ?? "/auth/callback",
    meta: auth_45callbackvzPvcsneV7Meta || {},
    alias: auth_45callbackvzPvcsneV7Meta?.alias || [],
    redirect: auth_45callbackvzPvcsneV7Meta?.redirect,
    component: () => import("/builds/rialtic/workflows/console-ui/packages/nuxt-auth0/src/runtime/pages/auth-callback.vue").then(m => m.default || m)
  },
  {
    name: auth_45login2NyvXp0YlaMeta?.name ?? "auth-login",
    path: auth_45login2NyvXp0YlaMeta?.path ?? "/auth/login",
    meta: auth_45login2NyvXp0YlaMeta || {},
    alias: auth_45login2NyvXp0YlaMeta?.alias || [],
    redirect: auth_45login2NyvXp0YlaMeta?.redirect,
    component: () => import("/builds/rialtic/workflows/console-ui/packages/nuxt-auth0/src/runtime/pages/auth-login.vue").then(m => m.default || m)
  },
  {
    name: auth_45statustPEZIB9azrMeta?.name ?? "auth-status",
    path: auth_45statustPEZIB9azrMeta?.path ?? "/auth/status",
    meta: auth_45statustPEZIB9azrMeta || {},
    alias: auth_45statustPEZIB9azrMeta?.alias || [],
    redirect: auth_45statustPEZIB9azrMeta?.redirect,
    component: () => import("/builds/rialtic/workflows/console-ui/packages/nuxt-auth0/src/runtime/pages/auth-status.vue").then(m => m.default || m)
  }
]